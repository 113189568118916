<template>
  <div class="share-on-social-networks">
    <div class="share-on-social-networks__wrapper">
      <Row align="center"
           justify="between"
           :wrap="$viewport.mobile">
        <span class="share-on-social-networks__title">Поделиться</span>
        <Row>
          <div :key="socialNetwork.id"
               class="social-network"
               v-for="socialNetwork in socialNetworks">
            <social-sharing :url="`${url}`"
                            :title="`${title}`"
                            :description="`${description}`"
                            inline-template>
              <div class="block">
                <network :network="`${socialNetwork.name !== 'ok'
                ? socialNetwork.name : 'odnoklassniki'}`">
                  <div :class="`social-network__icon-${socialNetwork.name}
                        social-network__icon-${socialNetwork.name}-active`"
                       class="social-network__icon">
                    <Icon :viewport="`${socialNetwork.viewport}`"
                          :xlink="`${socialNetwork.name}-icon`"/>
                  </div>
                </network>
              </div>
            </social-sharing>
          </div>
        </Row>
      </Row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShareOnSocialNetworks',
  props: {
    url: {
      type: String,
    },
    title: {
      type: String,
    },
    description: {
      type: String,
    },
  },
  data() {
    return {
      socialNetworks: [{
        name: 'vk',
        viewport: '0 0 29 17',
      // }, {
      //   name: 'facebook',
      //   viewport: '0 0 10 23',
      }, {
        name: 'ok',
        viewport: '0 0 14 24',
      }],
    };
  },
};
</script>
